<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')">
                <platenumber-con
                  @valueChange="
                    (v) => {
                      formInline.carId = v;
                    }
                  "
                ></platenumber-con>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')">
                <el-input
                  v-model.trim="formInline.phoneNumber"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Parking_card_name')">
                <el-input
                  v-model.trim="formInline.parkCardTitle"
                  maxlength="10"
                  placeholder="请输入停车卡名称"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="适用范围">
              <el-select v-model.trim="formInline.scopeType"
                        placeholder="请选择">
                <el-option label="请选择"
                          value="0"></el-option>
                <el-option label="全部车场"
                          value="1"></el-option>
                <el-option label="部分车场"
                          value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-show="formInline.scopeType == 2">
              <auto-complete ref="select"
                            @dataChange="v => (temParkData = v)"
                            @valueChange="completeValue"
                            slaveRelations="0,1"></auto-complete>
            </el-form-item> -->
              <!-- <el-form-item :label="$t('searchModule.Purchase_channels')">
              <el-select
                v-model.trim="formInline.buyType"
                filterable
                placeholder="请选择"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.desc"
                  :value="value.code + ''"
                  :key="value.code"
                  v-for="value in payTypeList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Order_status')">
              <el-select
                v-model.trim="formInline.state"
                filterable
                placeholder="请选择"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.desc"
                  :value="value.code + ''"
                  :key="value.code"
                  v-for="value in statusList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Payment_channels')" prop="channelType">
              <el-select v-model.trim="formInline.channelType">
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.channelTypeDesc"
                  :value="value.channelType"
                  :key="value.channelType"
                  v-for="value in channelList"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Payment_devices')">
              <el-select
                v-model.trim="formInline.devType"
                filterable
                placeholder="请选择"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.desc"
                  :value="value.code + ''"
                  :key="value.code"
                  v-for="value in exqList"
                ></el-option>
              </el-select>
            </el-form-item> -->
              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="areaIds"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Latest_payment_time')">
                <el-date-picker
                  v-model="datetimerange"
                  type="datetimerange"
                  range-separator="至"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Channel_serial_number')">
              <el-input
                v-model.trim="formInline.tradeNo"
                placeholder="请输入渠道流水号"
              ></el-input>
            </el-form-item> -->
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model.trim="formInline.state" filterable placeholder="请选择">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code + ''"
                    :key="value.code"
                    v-for="value in statusLists"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-dropdown @command="handleClick($event)">
                <el-button
                  type="primary"
                  v-if="$route.meta.authority.button.add"
                  icon="el-icon-plus"
                  :loading="loading"
                  >添加业务
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="add">添加业务</el-dropdown-item>
                  <el-dropdown-item command="export">批量导入</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="col_right mbd4">
              <el-button
                type="info"
                plain
                icon="el-icon-upload2"
                v-if="$route.meta.authority.button.export"
                @click="exportFile"
                >导出
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            type="plateNumber"
            :label="$t('searchModule.plate_number')"
            align="center"
            show-overflow-tooltip
            width="120"
          >
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.plateList" :key="index">
                <span
                  >{{ item.plateColor }} : {{ item.plateNumber }}
                  <span v-if="index != scope.row.plateList.length - 1">，</span></span
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="item.prop == 'useRangeDesc'"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template
              slot-scope="scope"
              v-if="$route.meta.authority.button.edit || $route.meta.authority.button.view"
            >
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item v-if="scope.row.canRenewal == 1" command="c"
                    >续费
                  </el-dropdown-item> -->
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item command="b">修改 </el-dropdown-item>
                  <!-- <el-dropdown-item
                    command="d"
                    v-if="scope.row.state != 4 && scope.row.state != 5"
                    >退款</el-dropdown-item
                  > -->
                  <el-dropdown-item command="e">日志</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      class="dialogHead"
      title="批量导入"
      :visible.sync="dialogVisibleupload"
      width="600px"
    >
      <div><span style="color: red">*</span>导入文件</div>
      <div style="margin-left: 100px">
        <div>
          <el-upload
            class="upload-demo"
            multiple
            drag
            ref="upload"
            :headers="header"
            :on-success="uploadSuccess"
            :limit="1"
            :on-exceed="handleExceed"
            :data="upData"
            action="/acb/2.0/parkCard/batchImport"
            :on-change="picChange"
            :auto-upload="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">点击上传文件或拖拽上传文件</div>
            <!-- <el-input
          v-model="fileName"
          style="width: 200px; float: left; margin-right: 5px"
        ></el-input> -->
            <!-- <el-button slot="trigger" @click="uploadClick" type="primary"
          >浏览...</el-button
        > -->
          </el-upload>
        </div>
        <div>
          <el-button type="text" @click="exportModel">下载导入模板</el-button>
        </div>
        <div>（提示：批量导入一行是一条记录，请控制在100条以内）</div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialogVisibleupload = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">{{ $t('button.import') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog class="dialog-card" title="停车卡续费" :visible.sync="cardDialog" width="600px">
      <div>
        <div style="padding-bottom: 14px; font-size: 16px">
          停车卡销售价格：
          <span style="font-weight: bold; font-size: 24px">{{
            sellingObj.actualAmount > 0 ? (sellingObj.actualAmount / 100).toFixed(2) : "0.00"
          }}</span>
          元
        </div>
        <div style="padding-bottom: 14px; font-size: 16px">是否确定后台续费？</div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="submitCard">确定续费</el-button>
        <el-button @click="cardDialog = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="停车卡退款"
      @closed="closeD"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      @close="dialogTableVisible = false"
      style="width: 1300px; margin-left: calc(50% - 650px); margin-top: 5%"
    >
      <el-form :model="formT" :rules="rules" ref="formT">
        <el-form-item label="退款金额" prop="money">
          <el-input
            size="12"
            :maxlength="8"
            v-model.trim="formT.money"
            placeholder="退款金额"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <el-card class="box-card" >
          <div class="el-icon-warning">停车卡未使用，建议金额 {{availableRefundMoney| moneyHandling("元") }} </div>
          <div class="el-icon-warning">按支付方式原路退回，此订单是现金支付，需要人工退款</div>
      </el-card> -->
      <div>
        <div style="margin-bottom: 5px">
          停车卡{{ useStateDesc }}，建议金额
          {{ availableRefundMoney | moneyHandling("元") }}
        </div>
        <div class="">
          按支付方式原路退回，此订单是{{ payTypeName }}，{{
            buyType == 2 ? "系统按原路退款" : "需要人工退款"
          }}
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="confirmRefund">确认退款</el-button>
        <el-button @click="closeD()">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import myComponent from "@/components/autocomplete/myautoComponentRoadside";
// import autoComplete from "@/components/autocomplete";
import platenumberCon from "@/components/plateNumber";
import { exportExcelNew, dateFormat } from "@/common/js/public";

export default {
  name: "parkingCardManage",
  data() {
    const originalPriceRule = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("请输入大于0的数字,最多两位小数"));
      } else if (!/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
        callback(new Error("请输入大于0的数字,最多两位小数"));
      } else {
        callback();
      }
    };
    const start = new Date();
    const end = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    return {
      areaIds: "",
      useStateDesc: "",
      formT: {
        money: "",
      },
      parkCardOrderId: "",
      availableRefundMoney: 0,
      payTypeName: "",
      buyType: "",
      dialogTableVisible: false,
      modelvalue: "",
      useRange: "",
      stateList: [],
      temParkData: {},
      datetimerange: [
        dateFormat(start, "yyyy-MM-dd HH:mm:ss"),
        dateFormat(end, "yyyy-MM-dd HH:mm:ss"),
      ],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      dialogVisibleupload: false, // 批量导入
      upData: {
        "Session-Id": sessionStorage.token,
      },
      header: {
        "Session-Id": sessionStorage.token,
      },
      tableCols: [
        // {
        //   prop: "plateNumber",
        //   label: this.$t("list.plate_number"),
        //   width: "",
        // },
        {
          prop: "phoneNumber",
          label: this.$t("list.phone_number"),
          width: "",
        },
        {
          prop: "parkCardTitle",
          label: "停车卡名称",
          width: "",
        },
        {
          prop: "useRangeDesc",
          label: this.$t("list.park_name"),
          width: "",
        },
        // {
        //   prop: "effectStartDate",
        //   label: "生效开始日期",
        //   width: "113"
        // },
        // {
        //   prop: "validityPeriod",
        //   label: "有效天数",
        //   width: ""
        // },
        {
          prop: "money",
          label: "总金额（元）",
          width: "",
          formatter: (row) => {
            return (row.money / 100).toFixed(2);
          },
        },
        // {
        //   prop: "payTypeDesc",
        //   label: "支付方式",
        // },
        // {
        //   prop: "channelTypeDesc",
        //   label: this.$t("list.Payment_channels"),
        // },
        // {
        //   prop: "devTypeDesc",
        //   label: this.$t("list.Payment_devices"),
        // },
        // {
        //   prop: "buyTypeDesc",
        //   label: "购买渠道",
        //   width: "",
        // },
        {
          prop: "lastPayTimeDesc",
          label: "最近支付时间",
          width: "",
        },
        {
          prop: "startDateDesc",
          label: "有效期起始",
          width: "",
        },
        {
          prop: "endDateDesc",
          label: "有效期截止",
          width: "",
        },
        {
          prop: "stateDesc",
          label: this.$t("list.state"),
          width: "",
        },
        {
          prop: "remark",
          label: "备注",
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        parkCardRuleId: "",
        scopeType: "0",
        parkId: "",
        phoneNumber: "",
        carId: "",
        parkCardTitle: "",
        buyType: "",
        scope: "",
        state: "",
        createTimeStart: "",
        createTimeEnd: "",
        devType: "",
        channelType: "",
        tradeNo: "",
        operationId: "",
      },
      payTypeList: [],
      scopeList: [],
      statusList: [],
      statusLists: [
        // 发生变动
        { code: 1, desc: "未生效" },
        { code: 2, desc: "生效中" },
        { code: 4, desc: "已失效" },
      ],
      exqList: [],
      channelList: [],
      cardDialog: false, // 停车卡续费弹窗
      sellingObj: 0, // 销售价格
      rules: {
        textarea: [{ required: true, message: "请填写处理理由", trigger: "blur" }],
        treatmentVlaue: [{ required: true, message: "请选择处理方式", trigger: "change" }],
        money: [
          {
            required: true,
            message: "请输入金额",
            trigger: "change",
          },
          {
            validator: originalPriceRule,
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    closeD() {
      this.dialogTableVisible = false;
      this.formT.money = "";
    },
    confirmRefund() {
      this.$refs.formT.validate((valid) => {
        if (valid) {
          //     if ((this.formT.money * 100) > this.availableRefundMoney || this.formT.money <= 0) {
          //     this.$alert("不能超过建议金额", this.$t('pop_up.Tips'), {
          //         confirmButtonText: this.$t('pop_up.Determine'),
          //     });
          //     // eslint-disable-next-line no-useless-return
          //     return;
          // }
          let data = {
            parkCardOrderId: this.parkCardOrderId,
            refundMoney: parseInt(this.formT.money * 100),
          };
          this.$axios.post("/acb/2.0/parkCardOrder/submitRefund", { data }).then((res) => {
            if (res.state == 0) {
              this.dialogTableVisible = false;
              this.formT.money = "";
              this.searchData();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        }
      });
    },
    // 获取退费上限
    getRefund() {
      // console.log("--data", data);
      this.$axios
        .get("/acb/2.0/parkCardOrder/getRefundInfo", {
          data: {
            parkCardOrderId: this.parkCardOrderId,
          },
        })
        .then((res) => {
          this.buyType = res.value.buyType;
          this.availableRefundMoney = res.value.availableRefundMoney;
          this.payTypeName = res.value.payTypeName;
          this.useStateDesc = res.value.useStateDesc;
        });
    },
    showconfirmRefund(data) {
      this.dialogTableVisible = true;
      this.parkCardOrderId = data.orderId;
      this.getRefund();
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1,2",
            areaIds: this.streetId ? this.streetId : this.areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            // this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getDownMenu() {
      this.$axios.get("/acb/2.0/parkCard/getSelectionValues").then((res) => {
        this.payTypeList = res.value.buyType;
        this.scopeList = res.value.scope;
        this.statusList = res.value.status;
      });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        console.log("11111111111111");
        // this.$router.push({ path: "/parkCardDetail", query: data });
        this.$router.push({
          path: "/parkCardDetail",
          query: data,
        });
      }
      if (cmd == "b") {
        this.$router.push({
          path: "/parkCardNewAdd",
          query: { ...data, isEdit: true },
        });
      }
      if (cmd == "c") {
        this.cardDialog = true;
        this.sellingObj = data;
      }
      if (cmd == "d") {
        this.showconfirmRefund(data);
      }
      if (cmd == "e") {
        this.$router.push({
          path: "/parkingCardDetailLog",
          query: data,
        });
      }
    },

    /**
     * 续费确定
     */
    submitCard() {
      let data = {
        parkCardId: this.sellingObj.parkCardId,
      };
      this.$axios.post("/acb/2.0/parkCard/renewalFee", { data }).then((res) => {
        if (res.state == 0) {
          this.cardDialog = false;
          this.$message({
            message: res.desc,
            type: "success",
          });
          this.searchData();
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },

    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    exportFile() {
      exportExcelNew("/acb/2.0/parkCard/export", {
        page: this.page,
        pageSize: this.pageSize,
        ...this.formInline,
      });
    },

    /**
     * 下载模板
     */
    exportModel() {
      let opt = {};
      opt = {
        scope: 1,
      };
      // exportExcelNew("/acb/2.0/bar/downloadExcelTemplate", opt);
      exportExcelNew("/acb/2.0/parkCard/batchImport/download/template", opt);
    },

    picChange(file, row) {
      if (file.name && !file.response) {
        this.fileName = file.name;
        this.picFlag = true;
      } else {
        this.$refs.upload.clearFiles();
        this.fileName = "";
        this.picFlag = false;
      }
    },

    submitUpload() {
      if (this.picFlag) {
        this.$refs.upload.submit();
        this.loading = true;
      } else {
        this.$alert("请选择需要上传的文件", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },

    /**
     * 导入
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/parkCard/batchImport", {
              data: {
                ...this.formInline,
              },
            })
            .then((res) => {
              this.loading = false;
              if (res.state == 0) {
                if (res.value.falseNums > 0) {
                  this.$alert(res.value.falseMsg, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  });
                } else {
                  this.dialogVisibleupload = false;
                  this.$message({
                    type: "success",
                    message: res.desc,
                  });
                  this.searchData();
                }
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
              // if (res.state == 0) {
              //  this.dialogVisible = false;
              //  this.searchData();
              // } else {
              //   this.$alert(res.desc, '提示');
              // }
            });
        }
      });
    },

    /**
     * 添加订单
     */
    handleClick(event) {
      if (event === "add") {
        this.$router.push("/parkCardNewAdd");
      } else {
        this.dialogVisibleupload = true;
      }
    },

    handleExceed(files, fileList) {
      this.$message.warning(`只能上传一个文件`);
    },
    // 文件上传成功
    uploadSuccess(res) {
      this.loading = false;
      if (res.state == 0) {
        if (res.value.falseNums > 0) {
          this.$alert(res.value.falseMsg, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        } else {
          this.dialogVisibleupload = false;
          this.$message({
            type: "success",
            message: res.desc,
          });
          this.searchData();
        }
      } else {
        this.$alert(res.desc, this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },

    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      if (this.formInline.scopeType == 1) {
        this.formInline.parkId = "";
      }
      if (this.datetimerange) {
        // this.formInline.createTimeStart = this.datetimerange[0];
        // this.formInline.createTimeEnd = this.datetimerange[1];
        this.formInline.lastPayTimeStart = this.datetimerange[0]; // 发生变动
        this.formInline.lastPayTimeEnd = this.datetimerange[1];
        delete this.formInline.createTimeStart;
        delete this.formInline.createTimeEnd;
      } else {
        this.loading = false;
        return this.$alert("请选择办理时间");
      }
      let url = "/acb/2.0/parkCard/list";
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((er) => {
          this.total = 0;
          this.loading = false;
        });
    },
    // 获取渠道列表
    getChannelList() {
      this.$axios.get("/acb/2.0/payOrder/payChannelTypeDictList").then((res) => {
        if (res.state == 0) {
          this.channelList = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取设备列表
    getExq(data) {
      this.$axios
        .get("/acb/2.0/payOrder/devTypeList")
        .then((res) => {
          // console.log("测试", res);
          this.exqList = res.value;
        })
        .catch(() => {
          this.exqList = [];
        });
    },
  },
  activated() {
    this.searchData();
  },
  components: {
    // autoComplete,
    platenumberCon,
    myComponent,
  },
  created() {
    this.getDownMenu();
    this.getChannelList();
    this.getExq();
    this.searchData();
  },
  computed: {},
  mounted() {
    if (this.$route.params.tradeNo) {
      this.formInline.tradeNo = this.$route.params.tradeNo;
      this.datetimerange = [];
    }
    if (this.$route.meta.authority) {
      let data = {};
      if (sessionStorage.getItem("authority")) {
        data = JSON.parse(sessionStorage.getItem("authority"));
      }
      data["parkList"] = this.$route.meta.authority;
      sessionStorage.setItem("authority", JSON.stringify(data));
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
>>>.dialogHead .el-dialog .el-dialog__header {
  padding: 13px 13px 13px 30px !important;
  background-color: #0f6eff;
  color: #fff;
}

>>>.dialog-card .el-dialog .el-dialog__header {
  background-color: #fff;
  color: #303133;
}

>>>.dialogHead >>>.el-dialog__title {
  color: #fff;
}

>>>.dialogHead >>>.el-icon-close {
  color: blue;

  &:hover {
    color: #fff;
  }
}

>>>.dialogHead >>>.el-progress--line {
  display: none !important;
}

>>>.dialogHead >>>.el-progress__text {
  display: none;
}
</style>
